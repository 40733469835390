<template>
  <div class="container is-fluid">
    <hr>
    <div class="columns is-multiline">
      <div class="column is-half-widescreen is-one-third-fullhd" v-for="order in orders" :key="order">
        <order :orderId="order"/>
      </div>
    </div>
  </div>
</template>

<script>
import Order from "./Order";
export default {
  computed: {
    orders() {
      return this.$route.query.orders;
    }
  },
  mounted() {
    if (this.$route.query.orders.length === 1) {
      this.$router.push({
        name: "order",
        params: { orderId: this.$route.query.orders[0] }
      });
    }
  },
  components: {
    Order
  }
};
</script>
